import service from '@/store/services/service'
import { noTransFormatter, notifyError, notifySuccess } from '@/utils/index'

const state = {
  // Admin
  adminList: null,
  roleList: null,
  permissionList: null,
  actionLogs: null,
  // User
  userList: null,
  userDetails: null,
  creditTransList: null,
  cashTransList: null,
  userProfiles: null,
  // Affiliate
  affiliateList: null,
  affiliateReferralList: null,
  affiliateTransactionList: null,
  AffiliateCampaignList: null,
  // Competition
  competitionList: null,
  competitionDetails: null,
  quizTypes: null,
  quizList: null,
  shortName: null,
  ticketList: null,
  prizeList: null,
  shuffleTickets: null,
  compApprovalTriggers: null,
  rankCompetitions: null,
  // Catalog
  catalogList: null,
  catalogCompetitions: null,
  // Product
  productList: null,
  productDetails: null,
  productInventory: null,
  invoices: null,
  // Attribute
  attributeList: null,
  productEntityList: null,
  // Order
  orderList: null,
  orderDetails: null,
  emailList: null,
  // Coupon
  couponList: null,
  couponCodeList: null,
  // Entry List
  entryList: null,
  // Draw List
  videoDrawList: null,
  // User Reward (Winner)
  winnerList: null,
  winnerDetails: null,
  // Notification
  notificationList: null,
  // Donation
  donationList: null,
  // Report
  salesReport: null,
  competitionReport: null,
  competitionDailyReport: null,
  usersReport: null,
  registeredUsers: null,
  dailyOrders: null,
  orderPayments: null,
  usersWithPurchaseReport: null,
  newUsrCompReport: null,
  stockReport: null,
  reEnterWeeklyReport: null,
  multiPayReport: null,
  affiliatesReport: null,
  userReentryMonthlyReport: null,
  // Topup
  topupList: null,
  topupPayments: null,
  // Cashback
  cashbackOptions: null,
  cashbackTrans: null,
  // System Config
  tabConfigs: null,
  databaseStats: null,
  databaseConfigHistory: null,
  // Banner
  bannerList: null,
  bannerDetails: null,
  // entryStatus: null,
  dailySales: null,
  userChannels: null,
  failedPayments: null,
  dailyCompetitionSales: null,
}

const getters = {}
for (const key in state) {
  getters[key] = state => state[key]
}

const mutations = {
  mutate(state, payload) {
    state[payload.property] = payload.with
  },
}

/**
 * Add general notification
 */
function generalCall(commit, call, _successMsg, _errorMsg) {
  return call()
    .then(res => {
      if (_successMsg) {
        notifySuccess(res, _successMsg)
      }
      if (commit) {
        commit(res.data.data)
      }
      return res
    })
    .catch(err => {
      if (_errorMsg) notifyError(err, _errorMsg)
    })
}

const actions = {
  mutateState({ commit }, params) {
    return commit('mutate', params)
  },
  // Admin
  getAdmins({ commit }, params) {
    const { successMsg, errorMsg, ...rest } = params ?? {}
    return generalCall(
      data => commit('mutate', { property: 'adminList', with: data }),
      () => service.getAdmins(rest),
      successMsg,
      errorMsg,
    )
  },
  getRoleList({ commit }, params) {
    const { successMsg, errorMsg, ...rest } = params ?? {}
    return generalCall(
      data => commit('mutate', { property: 'roleList', with: data }),
      () => service.getAllRoles(rest),
      successMsg,
      errorMsg,
    )
  },
  getPermissionList({ commit }, params) {
    const { successMsg, errorMsg, ...rest } = params ?? {}
    return generalCall(
      data => commit('mutate', { property: 'permissionList', with: data }),
      () => service.getAllPermissions(rest),
      successMsg,
      errorMsg,
    )
  },
  getActionLogs({ commit }, params) {
    const { successMsg, errorMsg, ...rest } = params ?? {}
    return generalCall(
      data => commit('mutate', { property: 'actionLogs', with: data }),
      () => service.getActionLogs(rest),
      successMsg,
      errorMsg,
    )
  },
  createAdmin(context, params) {
    const { successMsg, errorMsg, ...rest } = params ?? {}
    return generalCall(null, () => service.createAdmin(rest), successMsg, errorMsg)
  },
  changePass(context, params) {
    const { successMsg, errorMsg, ...rest } = params ?? {}
    return generalCall(null, () => service.changePass(rest), successMsg, errorMsg)
  },
  resetPass(context, params) {
    const { successMsg, errorMsg, ...rest } = params ?? {}
    return generalCall(null, () => service.resetPass(rest), successMsg, errorMsg)
  },
  forgetPass(context, params) {
    const { successMsg, errorMsg, ...rest } = params ?? {}
    return generalCall(null, () => service.forgetPass(rest), successMsg, errorMsg)
  },
  updateRole(context, params) {
    const { successMsg, errorMsg, ...rest } = params ?? {}
    return generalCall(null, () => service.updateRole(rest), successMsg, errorMsg)
  },
  updateRolePermission(context, params) {
    const { successMsg, errorMsg, ...rest } = params ?? {}
    return generalCall(null, () => service.updateRolePermission(rest), successMsg, errorMsg)
  },

  // User
  getUsers({ commit }, params) {
    const { successMsg, errorMsg, ...rest } = params ?? {}
    return generalCall(
      data => commit('mutate', { property: 'userList', with: data }),
      () => service.getUsers(rest),
      successMsg,
      errorMsg,
    )
  },
  getUserDetails({ commit }, params) {
    const { successMsg, errorMsg, ...rest } = params ?? {}
    return generalCall(
      data => commit('mutate', { property: 'userDetails', with: data }),
      () => service.getUserDetails(rest),
      successMsg,
      errorMsg,
    )
  },
  addUser(context, params) {
    const { successMsg, errorMsg, ...rest } = params ?? {}
    return generalCall(null, () => service.createUser(rest), successMsg, errorMsg)
  },
  updateUserDetails(context, params) {
    const { successMsg, errorMsg, ...rest } = params ?? {}
    return generalCall(null, () => service.updateUserDetails(rest), successMsg, errorMsg)
  },
  resetUserPass(context, params) {
    const { successMsg, errorMsg, ...rest } = params ?? {}
    return generalCall(null, () => service.resetUserPass(rest), successMsg, errorMsg)
  },
  addUserBalance(context, params) {
    const { successMsg, errorMsg, ...rest } = params ?? {}
    return generalCall(null, () => service.addUserBalance(rest), successMsg, errorMsg)
  },
  activateUser(context, params) {
    const { successMsg, errorMsg, ...rest } = params ?? {}
    return generalCall(null, () => service.activateUser(rest), successMsg, errorMsg)
  },

  // Competition
  getCompetitionList({ commit }, params) {
    const { successMsg, errorMsg, ...rest } = params ?? {}
    return generalCall(
      data => commit('mutate', { property: 'competitionList', with: data }),
      () => service.getCompetitionList(rest),
      successMsg,
      errorMsg,
    )
  },
  getCompetitionDetails({ commit }, params) {
    const { successMsg, errorMsg, ...rest } = params ?? {}
    return generalCall(
      data => {
        data['title'] = noTransFormatter(data.title, 'title', data)
        data['description'] = noTransFormatter(data.description, 'descr', data)
        commit('mutate', { property: 'competitionDetails', with: data })
      },
      () => service.getCompetitionDetails(rest),
      successMsg,
      errorMsg,
    )
  },
  getQuizTypes({ commit }, params) {
    const { successMsg, errorMsg, ...rest } = params ?? {}
    return generalCall(
      data => commit('mutate', { property: 'quizTypes', with: data }),
      () => service.getQuizTypes(rest),
      successMsg,
      errorMsg,
    )
  },
  getQuizList({ commit }, params) {
    const { successMsg, errorMsg, ...rest } = params ?? {}
    return generalCall(
      data => commit('mutate', { property: 'quizList', with: data }),
      () => service.getQuizList(rest),
      successMsg,
      errorMsg,
    )
  },
  getShortName({ commit }, params) {
    const { successMsg, errorMsg, ...rest } = params ?? {}
    return generalCall(
      data => commit('mutate', { property: 'shortName', with: data }),
      () => service.getShortName(rest),
      successMsg,
      errorMsg,
    )
  },
  getShuffleTickets({ commit }, params) {
    const { successMsg, errorMsg, ...rest } = params ?? {}
    return generalCall(
      data => commit('mutate', { property: 'shuffleTickets', with: data }),
      () => service.getShuffleTickets(rest),
      successMsg,
      errorMsg,
    )
  },
  upsertQuiz(context, params) {
    const { successMsg, errorMsg, ...rest } = params ?? {}
    return generalCall(null, () => service.upsertQuiz(rest), successMsg, errorMsg)
  },
  createCompetition(context, params) {
    const { successMsg, errorMsg, ...rest } = params ?? {}
    return generalCall(null, () => service.createCompetition(rest), successMsg, errorMsg)
  },
  updateCompetition(context, params) {
    const { successMsg, errorMsg, ...rest } = params ?? {}
    return generalCall(null, () => service.updateCompetition(rest), successMsg, errorMsg)
  },
  publishCompetition(context, params) {
    const { successMsg, errorMsg, ...rest } = params ?? {}
    return generalCall(null, () => service.publishCompetition(rest), successMsg, errorMsg)
  },
  closeCompetition(context, params) {
    const { successMsg, errorMsg, ...rest } = params ?? {}
    return generalCall(null, () => service.closeCompetition(rest), successMsg, errorMsg)
  },
  setTags(context, params) {
    const { successMsg, errorMsg, ...rest } = params ?? {}
    return generalCall(null, () => service.setTags(rest), successMsg, errorMsg)
  },
  setPopUp(context, params) {
    const { successMsg, errorMsg, ...rest } = params ?? {}
    return generalCall(null, () => service.setPopUp(rest), successMsg, errorMsg)
  },
  allocateRewards(context, params) {
    const { successMsg, errorMsg, ...rest } = params ?? {}
    return generalCall(null, () => service.allocateRewards(rest), successMsg, errorMsg)
  },
  selectWinner(context, params) {
    const { successMsg, errorMsg, ...rest } = params ?? {}
    return generalCall(null, () => service.selectWinner(rest), successMsg, errorMsg)
  },
  freeEntries(context, params) {
    const { successMsg, errorMsg, ...rest } = params ?? {}
    return generalCall(null, () => service.freeEntries(rest), successMsg, errorMsg)
  },

  // Catalog
  getCatalogList({ commit }, params) {
    const { successMsg, errorMsg, ...rest } = params ?? {}
    return generalCall(
      data => commit('mutate', { property: 'catalogList', with: data }),
      () => service.getCatalogList(rest),
      successMsg,
      errorMsg,
    )
  },
  getCatalogCompetitions({ commit }, params) {
    const { successMsg, errorMsg, ...rest } = params ?? {}
    return generalCall(
      data => commit('mutate', { property: 'catalogCompetitions', with: data }),
      () => service.getCatalogCompetitions(rest),
      successMsg,
      errorMsg,
    )
  },
  upsertCatalog(context, params) {
    const { successMsg, errorMsg, ...rest } = params ?? {}
    return generalCall(null, () => service.upsertCatalog(rest), successMsg, errorMsg)
  },
  updateCatalogCompetitions(context, params) {
    const { successMsg, errorMsg, payload } = params ?? {}
    return generalCall(null, () => service.updateCatalogCompetitions([...payload]), successMsg, errorMsg)
  },

  // Product
  getProductList({ commit }, params) {
    const { successMsg, errorMsg, ...rest } = params ?? {}
    return generalCall(
      data => commit('mutate', { property: 'productList', with: data }),
      () => service.getProductList(rest),
      successMsg,
      errorMsg,
    )
  },
  getProductDetails({ commit }, params) {
    const { successMsg, errorMsg, ...rest } = params ?? {}
    return generalCall(
      data => {
        data['name'] = noTransFormatter(data.name, 'name', data)
        data['description'] = noTransFormatter(data.description, 'descr', data)
        commit('mutate', { property: 'productDetails', with: data })
      },
      () => service.getProductDetails(rest),
      successMsg,
      errorMsg,
    )
  },
  getProductInventory({ commit }, params) {
    const { successMsg, errorMsg, ...rest } = params ?? {}
    return generalCall(
      data => commit('mutate', { property: 'productInventory', with: data }),
      () => service.getProductInventory(rest),
      successMsg,
      errorMsg,
    )
  },
  getInvoices({ commit }, params) {
    const { successMsg, errorMsg, ...rest } = params ?? {}
    return generalCall(
      data => commit('mutate', { property: 'invoices', with: data }),
      () => service.getInvoices(rest),
      successMsg,
      errorMsg,
    )
  },
  addProduct(context, params) {
    const { successMsg, errorMsg, ...rest } = params ?? {}
    return generalCall(null, () => service.createProduct(rest), successMsg, errorMsg)
  },
  updateProductInline(context, params) {
    const { successMsg, errorMsg, ...rest } = params ?? {}
    return generalCall(null, () => service.updateProductInline(rest), successMsg, errorMsg)
  },

  // Attribute
  getAttributeList({ commit }, params) {
    const { successMsg, errorMsg, ...rest } = params ?? {}
    return generalCall(
      data => commit('mutate', { property: 'attributeList', with: data }),
      () => service.getAttributeList(rest),
      successMsg,
      errorMsg,
    )
  },
  getProductEntityList({ commit }, params) {
    return service.getProductEntityList().then(res => commit('mutate', { property: 'productEntityList', with: res.data.data }))
  },

  // Order
  getOrderList({ commit }, params) {
    const { successMsg, errorMsg, ...rest } = params ?? {}
    return generalCall(
      data => commit('mutate', { property: 'orderList', with: data }),
      () => service.getOrderList(rest),
      successMsg,
      errorMsg,
    )
  },
  getOrderDetails({ commit }, params) {
    const { successMsg, errorMsg, ...rest } = params ?? {}
    return generalCall(
      data => commit('mutate', { property: 'orderDetails', with: data }),
      () => service.getOrderDetails(rest),
      successMsg,
      errorMsg,
    )
  },
  getEmailList({ commit }, params) {
    return service.getEmailList(params).then(res => {
      commit('mutate', { property: 'emailList', with: res.data.data })
    })
  },
  resendEmail(context, params) {
    const { successMsg, errorMsg, ...id } = params ?? {}
    return generalCall(null, () => service.resendEmail(id), successMsg, errorMsg)
  },

  // Coupon
  getCouponList({ commit }, params) {
    const { successMsg, errorMsg, ...rest } = params ?? {}
    return generalCall(
      data => commit('mutate', { property: 'couponList', with: data }),
      () => service.getCouponList(rest),
      successMsg,
      errorMsg,
    )
  },
  getCouponCode({ commit }, params) {
    const { successMsg, errorMsg, ...rest } = params ?? {}
    return generalCall(
      data => commit('mutate', { property: 'couponCodeList', with: data }),
      () => service.getCouponCode(rest),
      successMsg,
      errorMsg,
    )
  },
  upsertCoupon(context, params) {
    const { successMsg, errorMsg, ...rest } = params ?? {}
    return generalCall(null, () => service.upsertCoupon(rest), successMsg, errorMsg)
  },
  deleteCoupon(context, params) {
    const { successMsg, errorMsg, ...rest } = params ?? {}
    return generalCall(null, () => service.deleteCoupon(rest), successMsg, errorMsg)
  },
  createCouponCode(context, params) {
    const { successMsg, errorMsg, ...rest } = params ?? {}
    return generalCall(null, () => service.createCouponCode(rest), successMsg, errorMsg)
  },
  sendUserCouponCode(context, params) {
    const { successMsg, errorMsg, ...rest } = params ?? {}
    return generalCall(null, () => service.sendUserCouponCode(rest), successMsg, errorMsg)
  },
  assignCouponCodeUser(context, params) {
    const { successMsg, errorMsg, ...rest } = params ?? {}
    return generalCall(null, () => service.assignCouponCodeUser(rest), successMsg, errorMsg)
  },

  // Entry List
  getEntryList({ commit }, params) {
    const { successMsg, errorMsg, ...rest } = params ?? {}
    return generalCall(
      data => commit('mutate', { property: 'entryList', with: data }),
      () => service.getEntryList(rest),
      successMsg,
      errorMsg,
    )
  },
  updateEntryStatus(context, params) {
    const { successMsg, errorMsg, ...rest } = params ?? {}
    return generalCall(null, () => service.updateEntryStatus(rest), successMsg, errorMsg)
  },
  updateEntryList(context, params) {
    return service.updateEntryList(params)
  },
  updateEntryLink(context, params) {
    const { successMsg, errorMsg, ...rest } = params ?? {}
    return generalCall(null, () => service.updateEntryLink(rest), successMsg, errorMsg)
  },
  exportEntryList(context, params) {
    const { successMsg, errorMsg, ...rest } = params ?? {}
    return generalCall(null, () => service.exportEntryList(rest), successMsg, errorMsg)
  },

  // Draw List
  getVideoDrawList({ commit }, params) {
    const { successMsg, errorMsg, ...rest } = params ?? {}
    return generalCall(
      data => commit('mutate', { property: 'videoDrawList', with: data }),
      () => service.getVideoDrawList(rest),
      successMsg,
      errorMsg,
    )
  },
  updateVideoDrawStatus(context, params) {
    const { successMsg, errorMsg, ...rest } = params ?? {}
    return generalCall(null, () => service.updateVideoDrawStatus(rest), successMsg, errorMsg)
  },
  updateVideoDrawList(context, params) {
    const { successMsg, errorMsg, ...rest } = params ?? {}
    return generalCall(null, () => service.updateVideoDrawList(rest), successMsg, errorMsg)
  },

  // User Reward (Winner)
  getWinnerList({ commit }, params) {
    const { successMsg, errorMsg, ...rest } = params ?? {}
    return generalCall(
      data => commit('mutate', { property: 'winnerList', with: data }),
      () => service.getWinnerList(rest),
      successMsg,
      errorMsg,
    )
  },
  getWinnerDetails({ commit }, params) {
    const { successMsg, errorMsg, ...rest } = params ?? {}
    return generalCall(
      data => commit('mutate', { property: 'winnerDetails', with: data }),
      () => service.getWinnerDetails(rest),
      successMsg,
      errorMsg,
    )
  },
  updateWinnerDetail(context, params) {
    const { successMsg, errorMsg, ...rest } = params ?? {}
    return generalCall(null, () => service.updateWinnerDetail(rest), successMsg, errorMsg)
  },

  // Notification
  getNotificationList({ commit }, params) {
    const { successMsg, errorMsg, ...rest } = params ?? {}
    return generalCall(
      data => commit('mutate', { property: 'notificationList', with: data }),
      () => service.getNotificationList(rest),
      successMsg,
      errorMsg,
    )
  },

  // Donation
  getDonationList({ commit }, params) {
    const { successMsg, errorMsg, ...rest } = params ?? {}
    return generalCall(
      data => commit('mutate', { property: 'donationList', with: data }),
      () => service.getDonationList(rest),
      successMsg,
      errorMsg,
    )
  },
  upsertDonation(context, params) {
    const { successMsg, errorMsg, ...rest } = params ?? {}
    return generalCall(null, () => service.upsertDonation(rest), successMsg, errorMsg)
  },

  // Report
  getSalesReport({ commit }, params) {
    const { successMsg, errorMsg, ...rest } = params ?? {}
    return generalCall(
      data => commit('mutate', { property: 'salesReport', with: data }),
      () => service.getSalesReport(rest),
      successMsg,
      errorMsg,
    )
  },
  getCompetitionReport({ commit }, params) {
    const { successMsg, errorMsg, ...rest } = params ?? {}
    return generalCall(
      data => commit('mutate', { property: 'competitionReport', with: data }),
      () => service.getCompetitionReport(rest),
      successMsg,
      errorMsg,
    )
  },
  getCompetitionDailyReport({ commit }, params) {
    const { successMsg, errorMsg, ...rest } = params ?? {}
    return generalCall(
      data => commit('mutate', { property: 'competitionDailyReport', with: data }),
      () => service.getCompetitionDailyReport(rest),
      successMsg,
      errorMsg,
    )
  },
  getUsersReport({ commit }, params) {
    const { successMsg, errorMsg, ...rest } = params ?? {}
    return generalCall(
      data => commit('mutate', { property: 'usersReport', with: data }),
      () => service.getUsersReport(rest),
      successMsg,
      errorMsg,
    )
  },
  getUsersWithPurchaseReport({ commit }, params) {
    const { successMsg, errorMsg, ...rest } = params ?? {}
    return generalCall(
      data => commit('mutate', { property: 'usersWithPurchaseReport', with: data }),
      () => service.getUsersWithPurchaseReport(rest),
      successMsg,
      errorMsg,
    )
  },
  getRegisteredUsers({ commit }, params) {
    const { successMsg, errorMsg, ...rest } = params ?? {}
    return generalCall(
      data => commit('mutate', { property: 'registeredUsers', with: data }),
      () => service.getRegisteredUsers(rest),
      successMsg,
      errorMsg,
    )
  },
  getDailyOrders({ commit }, params) {
    const { successMsg, errorMsg, ...rest } = params ?? {}
    return generalCall(
      data => commit('mutate', { property: 'dailyOrders', with: data }),
      () => service.getDailyOrders(rest),
      successMsg,
      errorMsg,
    )
  },
  getOrderPayments({ commit }, params) {
    const { successMsg, errorMsg, ...rest } = params ?? {}
    return generalCall(
      data => commit('mutate', { property: 'orderPayments', with: data }),
      () => service.getOrderPayments(rest),
      successMsg,
      errorMsg,
    )
  },
  getNewUsrCompReport({ commit }, params) {
    return generalCall(
      data => commit('mutate', { property: 'newUsrCompReport', with: data }),
      () => service.getNewUsrCompReport(params),
    )
  },
  getStockReport({ commit }, params) {
    return generalCall(
      data => commit('mutate', { property: 'stockReport', with: data }),
      () => service.getStockReport(params),
    )
  },
  getReEnterWeeklyReport({ commit }, params) {
    return generalCall(
      data => commit('mutate', { property: 'reEnterWeeklyReport', with: data }),
      () => service.getReEnterWeeklyReport(params),
    )
  },

  // Topup
  getTopupList({ commit }, params) {
    const { successMsg, errorMsg, ...rest } = params ?? {}
    return generalCall(
      data => commit('mutate', { property: 'topupList', with: data }),
      () => service.getTopupList(rest),
      successMsg,
      errorMsg,
    )
  },
  getTopupPayments({ commit }, params) {
    const { successMsg, errorMsg, ...rest } = params ?? {}
    return generalCall(
      data => commit('mutate', { property: 'topupPayments', with: data }),
      () => service.getTopupPayments(rest),
      successMsg,
      errorMsg,
    )
  },
  updateTopup(context, params) {
    const { successMsg, errorMsg, ...rest } = params ?? {}
    return generalCall(null, () => service.updateTopup(rest), successMsg, errorMsg)
  },
  retrieveTopupPayment(context, params) {
    const { successMsg, errorMsg, ...rest } = params ?? {}
    return generalCall(null, () => service.retrieveTopupPayment(rest), successMsg, errorMsg)
  },

  // Cashback
  getCashbackOptions({ commit }, params) {
    const { successMsg, errorMsg, ...rest } = params ?? {}
    return generalCall(
      data => commit('mutate', { property: 'cashbackOptions', with: data }),
      () => service.getCashbackOptions(rest),
      successMsg,
      errorMsg,
    )
  },
  getCashbackTrans({ commit }, params) {
    const { successMsg, errorMsg, ...rest } = params ?? {}
    return generalCall(
      data => commit('mutate', { property: 'cashbackTrans', with: data }),
      () => service.getCashbackTrans(rest),
      successMsg,
      errorMsg,
    )
  },
  updateCashbackOption(context, params) {
    const { successMsg, errorMsg, ...rest } = params ?? {}
    return generalCall(null, () => service.updateCashbackOption(rest), successMsg, errorMsg)
  },
  auditCashback(context, params) {
    const { successMsg, errorMsg, ...rest } = params ?? {}
    return generalCall(null, () => service.auditCashback(rest), successMsg, errorMsg)
  },
  approveCashback(context, params) {
    const { successMsg, errorMsg, ...rest } = params ?? {}
    return generalCall(null, () => service.approveCashback(rest), successMsg, errorMsg)
  },

  // System Config
  getTabConfigs({ commit }, params) {
    const { successMsg, errorMsg, ...rest } = params ?? {}
    return generalCall(
      data => commit('mutate', { property: 'tabConfigs', with: data }),
      () => service.getTabConfigs(rest),
      successMsg,
      errorMsg,
    )
  },
  editSysConfigs(context, params) {
    const { successMsg, errorMsg, ...rest } = params ?? {}
    return generalCall(null, () => service.editSysConfigs(rest), successMsg, errorMsg)
  },

  // Banner
  getBannerList({ commit }, params) {
    const { successMsg, errorMsg, ...rest } = params ?? {}
    return generalCall(
      data => commit('mutate', { property: 'bannerList', with: data }),
      () => service.getBannerList(rest),
      successMsg,
      errorMsg,
    )
  },
  getCompetitionBanners({ commit }, params) {
    const { successMsg, errorMsg, ...rest } = params ?? {}
    return generalCall(
      data => commit('mutate', { property: 'bannerDetails', with: data }),
      () => service.getCompetitionBanners(rest),
      successMsg,
      errorMsg,
    )
  },
  upsertBanner(context, params) {
    const { successMsg, errorMsg, payload } = params ?? {}
    return generalCall(null, () => service.upsertBanner([...payload]), successMsg, errorMsg)
  },

  // Dashboard
  getDailySales({ commit }, params) {
    return service.getDailySales(params).then(res => {
      commit('mutate', { property: 'dailySales', with: res.data.data })
    })
  },
  getUserChannels({ commit }, params) {
    return service.getUserChannels(params).then(res => {
      commit('mutate', { property: 'userChannels', with: res.data.data })
    })
  },
  getFailedPayments({ commit }, params) {
    return service.getFailedPayments(params).then(res => {
      commit('mutate', { property: 'failedPayments', with: res.data.data })
    })
  },
  getDailyCompetitionSales({ commit }, params) {
    return service.getDailyCompetitionSales(params).then(res => {
      commit('mutate', { property: 'dailyCompetitionSales', with: res.data.data })
    })
  },
}

const request = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}

export default request
